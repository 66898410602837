"use client";

import React, { useState } from 'react';
import { AnimatePresence, motion } from "framer-motion";
import { IoIosInformationCircle } from 'react-icons/io';
import Loading from '../Loading';

interface BannerOnboardingProps {
    onClick?: () => void,
}

export default function BannerOnboarding({ onClick }: BannerOnboardingProps) {

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleClickButton = () => {
        if (isLoading) return
        setIsLoading(true)
        onClick?.()
    }

    return (
        <div className='absolute top-0 w-full flex flex-col items-center z-[49] md:px-0'>
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0, y: '-100%' }}
                    animate={{ opacity: 1, y: 0, transition: { duration: 0.3 } }}
                    exit={{ opacity: 0, y: '-100%', transition: { duration: 0.2 } }}
                    className={`w-full bg-[#FFECD7] min-h-7 flex flex-col border-t border-b border-[#FFB667] space-y-2 py-[14px] px-10`}
                >
                    <div className="flex flex-row justify-start space-x-2 items-center">
                        <IoIosInformationCircle className="text-[#EB9131] h-3 w-3" />
                        <p className="text-[#9E6931] text-sm font-bold">NOT YET LIVE</p>
                    </div>
                    <p className="font-bold text-sm text-[#062B5C]">You haven’t fully validated your profile yet.</p>
                    <p className="font-medium text-xs text-black-2">You won’t be visible to others—or be able to interact—until your profile has been verified. You can <span className="font-bold">always edit your profile.</span></p>
                    <div className="flex">
                        <div className="py-3 px-[13px] bg-[#FFB667] border-[3px] rounded-sm flex items-center justify-center min-w-[115px] min-h-[50px]" onClick={handleClickButton}>
                            {
                                isLoading ?
                                    <Loading className="!w-5 !h-5" />
                                    :
                                    <p className="font-bold text-sm text-black-2">Make it public</p>
                            }
                        </div>
                    </div>
                </motion.div>
            </AnimatePresence>
        </div>
    )
}