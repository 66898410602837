"use client"

import { motion, useAnimation } from "framer-motion"
import { ReactElement, useEffect, useRef, useState } from "react"
import { FaPause, FaPlay } from "react-icons/fa6"
import { IoClose } from "react-icons/io5";
import { checkMicrophonePermission } from "../MessageInput";
import useAuth from "@/hooks/useAuth";
import * as WavEncoder from "wav-encoder"; // Import the WAV encoder
import { auth } from "@/connectors/firebase";
import { sendMessageToAPI } from "@/services/api";
import { IMessage } from "@/interfaces/firebase";
import dayjs from "dayjs";
import IconArrowRight from "../IconArrowRight";
import IconClickIndicator from "../IconClickIndicator";
import { FaCircleXmark } from "react-icons/fa6";
import { FaRegCalendarAlt, FaUserCog } from "react-icons/fa";
import { FaRegCalendarCheck } from "react-icons/fa";
import { FaPeopleArrows } from "react-icons/fa6";
import { RxUpdate } from "react-icons/rx";

interface HandsFreeProps {
    conversationId: string;
    show: boolean;
    disableRecording?: boolean;
    isWaitingAI: boolean;
    messages: IMessage[];
    onClose?: () => void;
}

interface CommandLogProps {
    icon: ReactElement,
    text: string
}

const vCommandLogs = {
    normal: {
        height: "165px",
        transition: { type: "keyframe", duration: 0.3 },
    },
    expand: {
        height: "529px",
        transition: { type: "keyframe", duration: 0.3 },
    },
};

const vAIWaves = {
    normal: {
        height: "300px",
        transitionEnd: { display: "flex" },
        transition: { type: "keyframe", duration: 0.3 },
    },
    unexpand: {
        height: "0px",
        transitionEnd: { display: "none" },
        transition: { type: "keyframe", duration: 0.3 },
    },
};

export default function HandsFree({ conversationId, show = false, disableRecording = false, isWaitingAI = false, messages = [], onClose }: HandsFreeProps) {
    const { currentUser, setIsRecording } = useAuth();
    const statusHandsFree = useRef<"listen" | "waiting" | "responsing">("listen");
    const forceStop = useRef<boolean>(false);
    const showWavesResponsingRef = useRef<boolean>(false);
    const pauseRecording = useRef<boolean>(false);
    const [showWavesResponsing, setShowWavesResponsing] = useState<boolean>(false);
    const [recordingButton, setRecordingButton] = useState<boolean>(false);
    const [expandCommandLogs, setExpandCommandLogs] = useState<boolean>(false);
    const [commandLogsList, setCommandLogsList] = useState<CommandLogProps[]>([]);
    const [showHandsFree, setShowHandsFree] = useState<boolean>(show);
    const silenceTimeout = useRef<NodeJS.Timeout | null>(null);
    const isSilent = useRef<boolean>(false);
    const partialAudioChunks = useRef<Blob[]>([]);
    const [messagesLength, setMessagesLength] = useState<number>(0);
    const [audio, setAudio] = useState<string>("");
    const [canvasWidth, setCanvasWidth] = useState<number>(256);
    const [canvasResWidth, setCanvasResWidth] = useState<number>(256);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);
    const mainControls = useAnimation();

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const canvasCircleRef = useRef<HTMLCanvasElement | null>(null);
    const audioContextRef = useRef<AudioContext | null>(null);
    const analyserRef = useRef<AnalyserNode | null>(null);
    const dataArrayRef = useRef<Uint8Array | null>(null);
    const AudioRecordingRef = useRef<HTMLDivElement>(null);

    const canvasResRef = useRef<HTMLCanvasElement | null>(null);
    const audioContextResRef = useRef<AudioContext | null>(null);
    const analyserResRef = useRef<AnalyserNode | null>(null);
    const dataArrayResRef = useRef<Uint8Array | null>(null);
    const AudioResponsingRef = useRef<HTMLDivElement>(null);

    const AudioResponsingAIRef = useRef<HTMLAudioElement | null>(null);
    const commandLogRef = useRef<HTMLDivElement>(null)
    const audioContextReproduceRef = useRef<AudioContext | null>(null);
    const audioTagRef = useRef<HTMLAudioElement | null>(null);

    const sourceAudioRef = useRef<MediaElementAudioSourceNode | null>(null)

    useEffect(() => {
        if (recordingButton) {
            if (!disableRecording) setIsRecording(true);
            pauseRecording.current = false;
            if (!audioContextReproduceRef.current) {
                audioContextReproduceRef.current = new AudioContext();
            }
            if (!audioTagRef.current) {
                audioTagRef.current = new Audio("");
            }
            onRecording();
        } else {
            setIsRecording(false);
            pauseRecording.current = true;
            mediaRecorderRef.current?.stop();
            audioContextRef.current?.close();
            if (!showWavesResponsingRef.current) drawWaveformResPaused();
        }
    }, [recordingButton])

    useEffect(() => {
        setShowHandsFree(show)
        if (show) {
            setRecordingButton(true)
            forceStop.current = false
            showWavesResponsingRef.current = false
            setShowWavesResponsing(false)
            statusHandsFree.current = "listen"
        }
        if (!show) setRecordingButton(false)
    }, [show])

    useEffect(() => {
        getCommandLogs()
    }, [messages])

    useEffect(() => {
        if (showHandsFree) {
            mainControls.start("visible")
        }
        else {
            mainControls.start("hidden")
        }
    }, [showHandsFree])

    useEffect(() => {
        if (!showHandsFree) return
        if (messages.length == messagesLength && !isWaitingAI && !showWavesResponsingRef.current && !pauseRecording.current) {
            console.log("HF: LISTEN MODE")
            statusHandsFree.current = "listen"
        }
    }, [isWaitingAI, showWavesResponsing, showHandsFree])

    useEffect(() => {
        if (!showHandsFree) {
            console.log("HF: HERE 1")
            return
        }
        if (showWavesResponsingRef.current) {
            console.log("HF: HERE 2")
            return
        }
        if (!messages.length) {
            console.log("HF: HERE 3")
            return
        }
        if (messages.length == messagesLength) {
            console.log("HF: HERE 4")
            return
        }
        if (!messagesLength) {
            console.log("HF: HERE 5")
            setMessagesLength(messages.length)
            return
        }
        console.log("HF: WAITING MODE 2")
        statusHandsFree.current = "waiting"
        const AIMessages = newAIMessages()
        console.log("HF: WAITING MODE 2: ", dayjs(new Date()).format("HH:mm:ss"))
        console.log(AIMessages)
        if (!AIMessages.length) return
        console.log("HF: RESPONSING: ", dayjs(new Date()).format("HH:mm:ss"))
        AIResponse(AIMessages)
    }, [messages, showHandsFree, showWavesResponsing])

    const AIResponse = async (AIMessage: IMessage[]) => {
        for (const message of AIMessage) {
            console.log("HF: CALL TTS")
            if (message.data.role === "assistant") await TTS(message.data.content || "")
        }
        setMessagesLength(messages.length)
    }

    const newAIMessages = () => {
        const newMessagesOnly = messages.slice(messagesLength, messages.length)
        const AIMessagesOnly = newMessagesOnly.filter(f => f.data.role === "assistant" && f.data.content && !f.data.tool_calls)
        return AIMessagesOnly
    }

    const captureAudioData = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.requestData();
        }
    };

    const TTS = async (text: string): Promise<void> => {
        try {
            if (!text || !currentUser.id) return
            const token = (await auth.currentUser?.getIdToken(true)) ?? "";
            console.log("HF: TTS: ", dayjs(new Date()).format("HH:mm:ss"))
            let response = await sendMessageToAPI({
                token: token,
                route: "voice/TTS",
                data: {
                    id: currentUser.id,
                    text: removeSpecialCaracters(text)
                },
            });
            console.log("HF: RESPONSING MODE")
            showWavesResponsingRef.current = true
            setShowWavesResponsing(true)
            statusHandsFree.current = "responsing"
            const localURL = URL.createObjectURL(response)
            setAudio(localURL)
            return new Promise<void>(async (resolve) => {
                const audio = audioTagRef.current!
                if (!audio) return
                audio.src = localURL
                audio.load()
                const audioContext = audioContextReproduceRef.current!
                if (audioContext && audioContext.state === 'suspended') {
                    await audioContext.resume();
                }

                if (!sourceAudioRef.current) {
                    sourceAudioRef.current = audioContext.createMediaElementSource(audio)
                    const analyser = audioContext.createAnalyser()
                    analyser.fftSize = 1024;
                    analyser.connect(audioContext.destination);
                    sourceAudioRef.current.connect(analyser)
                    const bufferLength = analyser.frequencyBinCount
                    const dataArray = new Uint8Array(bufferLength)

                    audioContextResRef.current = audioContext
                    analyserResRef.current = analyser
                    dataArrayResRef.current = dataArray
                }

                audio.onended = () => {
                    console.log("HF: FINISH AUDIO")
                    showWavesResponsingRef.current = false
                    setShowWavesResponsing(false)
                    statusHandsFree.current = "waiting"

                    if (audioContextResRef.current) {
                        audioContext.close();
                        audioContextResRef.current.close();
                        audioContextResRef.current = null;
                    }
                    if (sourceAudioRef.current) sourceAudioRef.current.disconnect()
                    if (pauseRecording.current) {
                        drawWaveformResPaused();
                    }
                    resolve();
                };

                console.log("HF: TTS: ", dayjs(new Date()).format("HH:mm:ss"))
                AudioResponsingAIRef.current = audio

                console.log("AUDIO PLAY BEFORE")
                audio.play().then(() => {
                    console.log("AUDIO PLAYING")
                }).catch(error => {
                    console.log("AUDIO PLAYYYY")
                    console.log("Playback error: ", error)
                });
                drawWaveformRes()
            });
        } catch (error: any) {
            console.log(error)
        }
    }

    const getCommandLogs = async () => {
        let commandLogList: CommandLogProps[] = []
        for (const message of messages) {
            let filteredCommandLog = await filterCommandLog(message)
            if (filteredCommandLog?.text) commandLogList.push(filteredCommandLog)
        }
        // console.log(commandLogList)
        setCommandLogsList(commandLogList)
    }

    const filterCommandLog = async (message: IMessage): Promise<CommandLogProps | undefined> => {
        if (message.data.role !== "assistant") return
        let commandLog: CommandLogProps = { icon: <></>, text: "" }
        switch (message.data.tool_calls?.[0].function.name) {
            case "cancel_meeting":
                commandLog.icon = <FaCircleXmark className="text-primary-3.2 w-[15px] h-[15px]" />
                commandLog.text = "Canceled meeting: "
                break;
            case "change_current_user_profile":
                commandLog.icon = <FaUserCog className="text-primary-3.2 w-[15px] h-[15px]" />
                commandLog.text = "Updated you profile"
                break;
            case "change_user_socials":
                commandLog.icon = <FaUserCog className="text-primary-3.2 w-[15px] h-[15px]" />
                commandLog.text = "Updated your socials"
                break;
            case "confirm_meeting":
                commandLog.icon = <FaRegCalendarCheck className="text-primary-3.2 w-[15px] h-[15px]" />
                commandLog.text = "Confirmed meeting: "
                break;
            case "create_deal":
                commandLog.icon = <FaPeopleArrows className="text-primary-3.2 w-[15px] h-[15px]" />
                commandLog.text = "Deal created: "
                break;
            case "find_next_event":
                return;
            case "give_the_user_options":
                return;
            case "make_commitment":
                return;
            case "make_introduction":
                return;
            case "offer_meeting_time":
                return;
            case "recommend_events":
                return;
            case "request_more_information_about_deal":
                return;
            case "reschedule_meeting":
                commandLog.icon = <FaRegCalendarAlt className="text-primary-3.2 w-[15px] h-[15px]" />
                commandLog.text = "Rescheduled meeting: "
                break;
            case "search_for_deals":
                return;
            case "search_for_profiles":
                return;
            case "show_conference_agenda":
                return;
            case "show_interested_profiles":
                return;
            case "update_commitment":
                commandLog.icon = <RxUpdate className="text-primary-3.2 w-[15px] h-[15px]" />
                commandLog.text = "Updated commitment"
                break;
            case "update_deal":
                commandLog.icon = <RxUpdate className="text-primary-3.2 w-[15px] h-[15px]" />
                commandLog.text = "Updated deal"
                break;
            default:
                break;
        }
        return commandLog
    }

    const removeSpecialCaracters = (text: string): string => {
        return text.replace(/[^a-zA-Z ]/g, "")
    }

    const onRecording = async () => {
        try {
            const hasMicrophonePermission = await checkMicrophonePermission()
            if (!hasMicrophonePermission) {
                setRecordingButton(false)
                return;
            }

            navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
                const audioContext = new AudioContext()
                const source = audioContext.createMediaStreamSource(stream)
                const analyser = audioContext.createAnalyser()
                analyser.fftSize = 1024;
                analyser.minDecibels = -70
                const bufferLength = analyser.frequencyBinCount
                const dataArray = new Uint8Array(bufferLength)

                source.connect(analyser)
                audioContextRef.current = audioContext
                analyserRef.current = analyser
                dataArrayRef.current = dataArray

                mediaRecorderRef.current = new MediaRecorder(stream)
                mediaRecorderRef.current.ondataavailable = (event: BlobEvent) => {
                    if (event.data.size > 0) {
                        audioChunksRef.current.push(event.data)
                        partialAudioChunks.current.push(event.data)
                    }
                }

                mediaRecorderRef.current.start()

                setInterval(captureAudioData, 1000);

                const detectSilence = () => {
                    analyser.getByteTimeDomainData(dataArray);
                    const silenceThreshold = 128
                    const variation = 10
                    let silence = true
                    for (let i = 0; i < bufferLength; i++) {
                        if (dataArray[i] > silenceThreshold + variation || dataArray[i] < silenceThreshold - variation) {
                            silence = false
                            break
                        }
                    }
                    if (silence && statusHandsFree.current == "listen" && !forceStop.current && conversationId && !isWaitingAI && !disableRecording) {
                        if (!isSilent.current) {
                            const timeoutId = setTimeout(async () => {
                                if (partialAudioChunks.current.length > 0) {
                                    statusHandsFree.current = "waiting"
                                    drawWaveformResWaiting();
                                    console.log("HF: WAITING MODE 1")
                                    let newBlob = audioChunksRef.current.slice(partialAudioChunks.current.length, audioChunksRef.current.length)
                                    let cuttingOriginalAudio = [audioChunksRef.current[0]]
                                    const partialAudioBlob = new Blob(newBlob.length ? cuttingOriginalAudio.concat(newBlob) : partialAudioChunks.current, { type: "audio/webm" })
                                    try {
                                        const audioBuffer = await partialAudioBlob.arrayBuffer()
                                        const audioData = await audioContext.decodeAudioData(audioBuffer)
                                        const wavBuffer = await WavEncoder.encode({
                                            sampleRate: audioData.sampleRate,
                                            channelData: [audioData.getChannelData(0)],
                                        })

                                        const wavBlob = new Blob([wavBuffer], { type: "audio/wav" })
                                        const wavArrayBuffer = await wavBlob.arrayBuffer();
                                        const buffer = Buffer.from(wavArrayBuffer);
                                        const token = (await auth.currentUser?.getIdToken(true)) ?? "";
                                        console.log("HF: STT: ", dayjs(new Date()).format("HH:mm:ss"))
                                        sendMessageToAPI({
                                            token: token,
                                            route: "voice/STT",
                                            data: {
                                                id: currentUser.id,
                                                conversationId: conversationId,
                                                audio: buffer
                                            },
                                        });
                                        console.log("HF: STT: ", dayjs(new Date()).format("HH:mm:ss"))
                                        partialAudioChunks.current = []
                                    } catch (error) {
                                        statusHandsFree.current = "listen"
                                        console.error("Error decoding audio data:", error)
                                    }
                                }
                                silenceTimeout.current = null
                            }, 3000)
                            silenceTimeout.current = timeoutId
                        }
                        isSilent.current = true
                    } else {
                        if (silenceTimeout.current) {
                            clearTimeout(silenceTimeout.current)
                            silenceTimeout.current = null
                        }
                        isSilent.current = false
                    }
                    setRecordingButton((prev) => {
                        if (prev) requestAnimationFrame(detectSilence)
                        return prev
                    })
                }

                detectSilence()
                drawWaveformCircle()
                drawWaveform()
            });
        } catch (error) {
            console.log(error)
        }
    };

    const drawWaveformCircle = () => {
        if (!canvasCircleRef.current || !analyserRef.current || !dataArrayRef.current)
            return;

        const canvas = canvasCircleRef.current;
        const canvasCtx = canvas.getContext("2d");
        const analyser = analyserRef.current;
        const dataArray = dataArrayRef.current;
        let clearRecording = true;


        const drawCircle = (recording: boolean, fadeStep: number = 0) => {
            if (!canvasCtx) return;

            analyser.getByteFrequencyData(dataArray);
            const dataArrayAsArray = Array.from(dataArray);
            const maxFrequency = Math.max(...dataArrayAsArray);
            const minCanvasSize = Math.min(canvas.width, canvas.height);
            const minRadius = minCanvasSize * 0.25;
            const maxRadius = minCanvasSize * 0.45;
            let radius = minRadius + (maxFrequency / 255) * (maxRadius - minRadius);

            if (fadeStep > 0) {
                radius -= fadeStep;
                if (radius < 0) radius = 0;
            }

            const opacity = 0.5 + (maxFrequency / 255) * 0.7;
            canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
            canvasCtx.beginPath();
            canvasCtx.arc(canvas.width / 2, canvas.height / 2, radius, 0, Math.PI * 2);
            canvasCtx.closePath();
            canvasCtx.fillStyle = `rgba(78, 146, 255, ${opacity * 0.7})`;
            canvasCtx.fill();

            const innerRadius = radius * 0.65;
            canvasCtx.beginPath();
            canvasCtx.arc(canvas.width / 2, canvas.height / 2, innerRadius, 0, Math.PI * 2);
            canvasCtx.closePath();
            canvasCtx.fillStyle = `rgba(78, 146, 255, ${opacity})`;
            canvasCtx.fill();

            if (!recording) {
                if (!clearRecording) {
                    requestAnimationFrame(() => drawCircle(false, fadeStep + 3));
                }
                return;
            }

            clearRecording = false;
            setRecordingButton((prev) => {
                requestAnimationFrame(() => drawCircle(prev));
                return prev;
            });
        };

        drawCircle(true);
    };

    const drawWaveformResWaiting = () => {
        if (!canvasResRef.current) return
        if (statusHandsFree.current !== "waiting") return

        const canvas = canvasResRef.current;
        const canvasCtx = canvas.getContext("2d");
        let loadingPosition = 0;
        const loadingSpeed = 0.1;

        const drawWaiting = (isRecording: boolean) => {
            if (!canvasCtx) return;

            const barWidth = 6;
            const barGap = 4;
            const minBarHeight = 6;
            const barRadius = 5;
            const barCount = canvas.width / (barWidth + barGap);
            let axleY, barHeight: number;

            canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
            canvasCtx.fillStyle = "#FF000000";
            canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

            for (let i = 0; i < barCount; i++) {
                barHeight = minBarHeight;

                if (i >= Math.floor(loadingPosition) - 1 && i <= Math.floor(loadingPosition) + 3) {
                    if (i === Math.floor(loadingPosition) || i === Math.floor(loadingPosition) + 1 || i === Math.floor(loadingPosition) + 2) {
                        canvasCtx.fillStyle = "#FFFFFF";
                    } else {
                        canvasCtx.fillStyle = "#AAAAAA";
                    }
                } else {
                    canvasCtx.fillStyle = "#4E92FF";
                }

                axleY = (canvas.height - barHeight) / 2;

                canvasCtx.beginPath();
                canvasCtx.moveTo(i * (barWidth + barGap), axleY + barRadius);
                canvasCtx.lineTo(i * (barWidth + barGap), axleY + barHeight - barRadius);
                canvasCtx.arcTo(
                    i * (barWidth + barGap),
                    axleY + barHeight,
                    i * (barWidth + barGap) + barRadius,
                    axleY + barHeight,
                    barRadius
                );
                canvasCtx.lineTo(
                    i * (barWidth + barGap) + barWidth - barRadius,
                    axleY + barHeight
                );
                canvasCtx.arcTo(
                    i * (barWidth + barGap) + barWidth,
                    axleY + barHeight,
                    i * (barWidth + barGap) + barWidth,
                    axleY + barHeight - barRadius,
                    barRadius
                );
                canvasCtx.lineTo(
                    i * (barWidth + barGap) + barWidth,
                    axleY + barRadius
                );
                canvasCtx.arcTo(
                    i * (barWidth + barGap) + barWidth,
                    axleY,
                    i * (barWidth + barGap) + barWidth - barRadius,
                    axleY,
                    barRadius
                );
                canvasCtx.lineTo(
                    i * (barWidth + barGap) + barRadius,
                    axleY
                );
                canvasCtx.arcTo(
                    i * (barWidth + barGap),
                    axleY,
                    i * (barWidth + barGap),
                    axleY + barRadius,
                    barRadius
                );
                canvasCtx.closePath();
                canvasCtx.fill();
            }
            if (!isRecording) {
                canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
                return
            }
            loadingPosition = (loadingPosition + loadingSpeed) % barCount;
            if (statusHandsFree.current == "waiting") {
                requestAnimationFrame(() => drawWaiting(true));
            }
            else {
                requestAnimationFrame(() => drawWaiting(false));
            }
            return;
        };

        drawWaiting(true);
    };

    const drawWaveformResPaused = () => {
        if (!canvasResRef.current) return

        const canvas = canvasResRef.current;
        const canvasCtx = canvas.getContext("2d");

        const drawPaused = (isRecording: boolean) => {
            if (!canvasCtx) return;

            const barWidth = 6;
            const barGap = 4;
            const minBarHeight = 6;
            const barRadius = 5;
            const barCount = canvas.width / (barWidth + barGap);
            let axleY, barHeight: number;

            canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
            canvasCtx.fillStyle = "#FF000000";
            canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

            for (let i = 0; i < barCount; i++) {
                barHeight = minBarHeight;

                canvasCtx.fillStyle = "#4E92FF";

                axleY = (canvas.height - barHeight) / 2;

                canvasCtx.beginPath();
                canvasCtx.moveTo(i * (barWidth + barGap), axleY + barRadius);
                canvasCtx.lineTo(i * (barWidth + barGap), axleY + barHeight - barRadius);
                canvasCtx.arcTo(
                    i * (barWidth + barGap),
                    axleY + barHeight,
                    i * (barWidth + barGap) + barRadius,
                    axleY + barHeight,
                    barRadius
                );
                canvasCtx.lineTo(
                    i * (barWidth + barGap) + barWidth - barRadius,
                    axleY + barHeight
                );
                canvasCtx.arcTo(
                    i * (barWidth + barGap) + barWidth,
                    axleY + barHeight,
                    i * (barWidth + barGap) + barWidth,
                    axleY + barHeight - barRadius,
                    barRadius
                );
                canvasCtx.lineTo(
                    i * (barWidth + barGap) + barWidth,
                    axleY + barRadius
                );
                canvasCtx.arcTo(
                    i * (barWidth + barGap) + barWidth,
                    axleY,
                    i * (barWidth + barGap) + barWidth - barRadius,
                    axleY,
                    barRadius
                );
                canvasCtx.lineTo(
                    i * (barWidth + barGap) + barRadius,
                    axleY
                );
                canvasCtx.arcTo(
                    i * (barWidth + barGap),
                    axleY,
                    i * (barWidth + barGap),
                    axleY + barRadius,
                    barRadius
                );
                canvasCtx.closePath();
                canvasCtx.fill();
            }
            if (!isRecording) {
                canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
                return
            }
            if (pauseRecording.current) {
                requestAnimationFrame(() => drawPaused(true));
            }
            else {
                requestAnimationFrame(() => drawPaused(false));
            }
            return;
        };

        drawPaused(true);
    };

    const drawWaveformRes = () => {
        if (!canvasResRef.current || !analyserResRef.current || !dataArrayResRef.current) return;

        const canvas = canvasResRef.current;
        const canvasCtx = canvas.getContext("2d");
        const analyser = analyserResRef.current;
        const dataArray = dataArrayResRef.current;

        const draw = () => {
            if (!canvasCtx) return;

            const barWidth = 6;
            const barGap = 4;
            const minBarHeight = 6;
            const barRadius = 5;
            const barCount = canvas.width / (barWidth + barGap);
            let axleY, barHeight: number;
            let newBarHeights = [];
            let clearRecording = true;

            analyser.getByteFrequencyData(dataArray);
            canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

            for (let i = 0; i < barCount; i++) {
                barHeight = (dataArray[i] / 255) * canvas.height;
                barHeight = barHeight <= 0 ? minBarHeight : barHeight;

                if (clearRecording) clearRecording = barHeight > 0 ? false : true;
                newBarHeights.push(barHeight);

                canvasCtx.fillStyle = "#FFFFFF";
                axleY = (canvas.height - barHeight) / 2;

                canvasCtx.beginPath();
                canvasCtx.moveTo(i * (barWidth + barGap), axleY + barRadius);
                canvasCtx.lineTo(i * (barWidth + barGap), axleY + barHeight - barRadius);
                canvasCtx.arcTo(
                    i * (barWidth + barGap),
                    axleY + barHeight,
                    i * (barWidth + barGap) + barRadius,
                    axleY + barHeight,
                    barRadius
                );
                canvasCtx.lineTo(
                    i * (barWidth + barGap) + barWidth - barRadius,
                    axleY + barHeight
                );
                canvasCtx.arcTo(
                    i * (barWidth + barGap) + barWidth,
                    axleY + barHeight,
                    i * (barWidth + barGap) + barWidth,
                    axleY + barHeight - barRadius,
                    barRadius
                );
                canvasCtx.lineTo(
                    i * (barWidth + barGap) + barWidth,
                    axleY + barRadius
                );
                canvasCtx.arcTo(
                    i * (barWidth + barGap) + barWidth,
                    axleY,
                    i * (barWidth + barGap) + barWidth - barRadius,
                    axleY,
                    barRadius
                );
                canvasCtx.lineTo(
                    i * (barWidth + barGap) + barRadius,
                    axleY
                );
                canvasCtx.arcTo(
                    i * (barWidth + barGap),
                    axleY,
                    i * (barWidth + barGap),
                    axleY + barRadius,
                    barRadius
                );
                canvasCtx.closePath();
                canvasCtx.fill();
            }

            if (showWavesResponsingRef.current) {
                requestAnimationFrame(draw);
            } else {
                canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
            }
        };

        draw();
    };

    const drawWaveform = () => {
        if (!canvasRef.current || !analyserRef.current || !dataArrayRef.current)
            return;

        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext("2d");
        const analyser = analyserRef.current;
        const dataArray = dataArrayRef.current;
        let barHeights: number[] = [];

        const draw = (recording: boolean, heightLine: number = 0) => {
            if (!canvasCtx) return;

            const barWidth = 6;
            const barGap = 4;
            const minBarHeight = 6;
            const barRadius = 5;
            const barCount = canvas.width / (barWidth + barGap);
            let axleY, barHeight: number;
            let newBarHeights = [];
            let clearRecording = true;

            analyser.getByteFrequencyData(dataArray);
            canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
            canvasCtx.fillStyle = "#FF000000";
            canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

            for (let i = 0; i < barCount; i++) {
                barHeight = recording
                    ? (dataArray[i] / 255) * canvas.height
                    : barHeights[i];
                barHeight = recording
                    ? barHeight
                    : barHeight - heightLine <= 0
                        ? 0
                        : barHeight - heightLine;

                barHeight = barHeight <= 0 && recording ? minBarHeight : barHeight;
                if (clearRecording) clearRecording = barHeight > 0 ? false : true;
                newBarHeights.push(barHeight);
                canvasCtx.fillStyle = "#C0C9E2";

                axleY = (canvas.height - barHeight) / 2;

                canvasCtx.beginPath();
                canvasCtx.moveTo(i * (barWidth + barGap), axleY + barRadius);
                canvasCtx.lineTo(i * (barWidth + barGap), axleY + barHeight - barRadius);
                canvasCtx.arcTo(
                    i * (barWidth + barGap),
                    axleY + barHeight,
                    i * (barWidth + barGap) + barRadius,
                    axleY + barHeight,
                    barRadius
                );
                canvasCtx.lineTo(
                    i * (barWidth + barGap) + barWidth - barRadius,
                    axleY + barHeight
                );
                canvasCtx.arcTo(
                    i * (barWidth + barGap) + barWidth,
                    axleY + barHeight,
                    i * (barWidth + barGap) + barWidth,
                    axleY + barHeight - barRadius,
                    barRadius
                );
                canvasCtx.lineTo(
                    i * (barWidth + barGap) + barWidth,
                    axleY + barRadius
                );
                canvasCtx.arcTo(
                    i * (barWidth + barGap) + barWidth,
                    axleY,
                    i * (barWidth + barGap) + barWidth - barRadius,
                    axleY,
                    barRadius
                );
                canvasCtx.lineTo(
                    i * (barWidth + barGap) + barRadius,
                    axleY
                );
                canvasCtx.arcTo(
                    i * (barWidth + barGap),
                    axleY,
                    i * (barWidth + barGap),
                    axleY + barRadius,
                    barRadius
                );
                canvasCtx.closePath();
                canvasCtx.fill();
            }
            if (!recording && clearRecording) canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

            barHeights = newBarHeights;
            if (!recording) {
                if (!clearRecording) requestAnimationFrame(() => draw(false, 3));
                return;
            }
            setRecordingButton((prev) => {
                requestAnimationFrame(() => draw(prev));
                return prev;
            });
        };

        draw(true);
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            if (entries[0]) {
                setCanvasWidth(entries[0].contentRect.width);
            }
        });

        setTimeout(() => {
            if (AudioRecordingRef.current) {
                resizeObserver.observe(AudioRecordingRef.current);
            }
        }, 200);


        return () => {
            if (AudioRecordingRef.current) {
                resizeObserver.unobserve(AudioRecordingRef.current);
            }
        };
    }, [AudioRecordingRef.current]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            if (entries[0]) {
                setCanvasResWidth(entries[0].contentRect.width);
            }
        });

        if (AudioResponsingRef.current) {
            resizeObserver.observe(AudioResponsingRef.current);
        }

        return () => {
            if (AudioResponsingRef.current) {
                resizeObserver.unobserve(AudioResponsingRef.current);
            }
        };
    }, [AudioResponsingRef.current]);

    const handleRecording = () => {
        setRecordingButton(!recordingButton)
    }

    const handleCloseHandsFree = () => {
        if (statusHandsFree.current != "waiting") {
            onClose?.()
            forceStop.current = true
            if (AudioResponsingAIRef.current) AudioResponsingAIRef.current.pause()
        }
    }

    const expandCommandLog = () => {
        if (commandLogsList.length < 3) return
        setRecordingButton(false)
        if (!recordingButton) {
            setExpandCommandLogs(!expandCommandLogs)
            return
        }
        setTimeout(() => {
            setExpandCommandLogs(!expandCommandLogs)
        }, 1000);
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (commandLogRef.current && !commandLogRef.current.contains(event.target as Node)) {
            setExpandCommandLogs(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <motion.div
            variants={{
                hidden: { opacity: 0, zIndex: -10 },
                visible: { opacity: 1, zIndex: 20 }
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.4, delay: 0.1 }}
            className="absolute top-0 h-[calc(100vh_-_55px)] w-full bg-primary-3 handsfree-bg-with-gradient overflow-hidden flex flex-col items-center justify-between p-10"
        >
            <div className="w-full space-y-3">
                <div className="space-x-3 flex items-center">
                    <IconArrowRight color="#FFFFFF" className="rotate-180" />
                    <p className="text-white font-bold text-2xl">
                        Hands Free Mode
                    </p>
                </div>
                <hr className="border-primary-3.2 w-full !mt-5" />
            </div>
            <motion.div
                layout
                initial={"normal"}
                animate={expandCommandLogs ? "unexpand" : "normal"}
                variants={vAIWaves}
                className="relative w-[300px] justify-center items-center"
            >
                <div>
                    <canvas className={`bg-transparency opacity-20 !m-0`} ref={canvasCircleRef} width="300" height="300"></canvas>
                </div>
                <div ref={AudioResponsingRef} className="w-full h-[100px] absolute px-[30px] flex justify-center items-center">
                    <canvas className={`bg-transparency absolute !m-0`} ref={canvasResRef} width={canvasResWidth} height="100"></canvas>
                </div>
                <audio
                    className="hidden"
                    src={audio}
                    crossOrigin="anonymous"
                    controls
                    playsInline
                >
                </audio>
            </motion.div>
            <div className="w-full space-y-10">
                <div className="space-y-[6px]" ref={commandLogRef}>
                    <div className="flex justify-between items-center">
                        <motion.p layout className={`${expandCommandLogs ? "text-white" : "text-primary-4"} font-bold text-sm highlight-handsFree-1 highlight-handsFree-1-left-[50px]`}>Command log</motion.p>
                        {
                            commandLogsList.length > 2 ?
                                <div className="space-x-2 flex flex-row items-center" onClick={expandCommandLog}>
                                    <motion.p layout className={`${expandCommandLogs ? "text-white" : "text-primary-4"} font-bold text-sm`}>{expandCommandLogs ? "collapse" : "expand"}</motion.p>
                                    <IconClickIndicator className={`${expandCommandLogs ? "text-white" : "text-primary-4"}`} />
                                </div>
                                : null
                        }
                    </div>
                    <motion.div
                        layout
                        initial={"normal"}
                        animate={expandCommandLogs ? "expand" : "normal"}
                        variants={vCommandLogs}
                        className={`rounded-[15px] bg-primary-3.1 border border-primary-3.2 w-full pl-[15px] pr-[8px] py-[17px]`}
                        style={{ transformOrigin: "bottom" }}
                    >
                        <div
                            className={`overflow-x-hidden overflow-y-auto w-full h-full flex flex-col snap-y scrollbar-thin pr-[6px] flex-grow`}
                        >
                            {commandLogsList.length ? (
                                <div
                                    className="flex-grow flex flex-col justify-end space-y-[10px]"
                                    ref={(el) => {
                                        if (el) el.scrollTop = el.scrollHeight;
                                    }}
                                >
                                    {commandLogsList.map((cl, key) => (
                                        <div key={key} className="w-full h-auto px-[13px] py-[10px] space-x-[10px] flex flex-row justify-between items-start rounded-[18px] bg-grey-1">
                                            {cl.icon}
                                            <p className="w-[calc(100%_-_40px)] font-bold text-sm text-primary-2">{cl.text}</p>
                                            <IconClickIndicator className="text-grey-2.2 w-[10px] h-[10px]" />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className="text-primary-4 font-medium text-sm">Actions taken by the AI will appear here</p>
                            )}
                        </div>
                    </motion.div>

                </div>
                <div className="flex flex-row justify-between items-center">
                    <div className="w-[50px] h-[50px] flex justify-center items-center bg-primary-1 rounded-full highlight-handsFree-2 highlight-handsFree-2-left-[17px] highlight-handsFree-2-top-[20px]" onClick={handleCloseHandsFree}>
                        <IoClose className="text-primary-4 w-6 h-6" />
                    </div>
                    <div className="w-[calc(100%_-_120px)] h-14 flex items-center justify-center">
                        {
                            recordingButton ?
                                <div ref={AudioRecordingRef} className="w-full h-14">
                                    <canvas className={`bg-transparency absolute !m-0`} ref={canvasRef} width={canvasWidth} height="56"></canvas>
                                </div>
                                :
                                <p className="text-white font-bold text-sm">paused transmission</p>
                        }
                    </div>
                    <div className="w-[50px] h-[50px] flex justify-center items-center bg-white rounded-full highlight-handsFree-2 highlight-handsFree-2-left-[17px] highlight-handsFree-2-top-[20px]" onClick={handleRecording}>
                        {
                            recordingButton ?
                                <FaPause className="text-primary-3.1 w-6 h-6" />
                                : <FaPlay className="text-primary-3.1 w-6 h-6" />
                        }
                    </div>
                </div>
            </div>
        </motion.div>
    )
}