"use client";

import Alert from "@/components/Alert";
import MessageAssistant from "@/components/MessageAssistant";
import MessageInput from "@/components/MessageInput";
import useAuth from "@/hooks/useAuth";
import { useRouter, useSearchParams } from "next/navigation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { auth } from "@/connectors/firebase";
import { Conversation, IMessage } from "@/interfaces/firebase";
import { Timestamp } from "firebase/firestore";
import { sendMessageToAI } from "@/services/ai";
import { useScroll } from "@/hooks/scroll";
import DynamicComponent from "@/components/DynamicComponent";
import { sendMessageToAPI } from "@/services/api";
import { useNotifications } from "@/contexts/notifyContext";
import HandsFree from "@/components/HandsFree";
import Suggestions from "@/components/Suggestions";
import Skeleton from "@/components/Skeleton";
import { useTutorial } from "@/contexts/TutorialContext";
import { v4 as uuidv4 } from "uuid";
import { chatsProps, useMessages } from "@/contexts/messagesContext";
import { actionAI } from "@/functions/actionChecker";
import PreLoadingComponents from "@/components/PreLoadingComponents";
import LoadingAiResponse from "@/components/LoadingAIResponse";
import BannerOnboarding from "@/components/BannerOnboarding";
import Loading from "@/components/Loading";
import { motion } from "framer-motion";
import { useQueryClient } from "@tanstack/react-query";

export default function Home() {
  const route = useRouter();
  const { addNotification } = useNotifications();
  const waitingAiEl = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { userLoggedIn, currentUser, setUser, initialized } = useAuth();
  const scroll = useScroll();
  const searchParams = useSearchParams();
  const [allMessages, setAllMessages] = useState<IMessage[]>([]);
  const [loadMessages, setLoadMessages] = useState(false);
  const [secondCardOnboardingLoad, setSecondCardOnboardingLoad] = useState(false);
  const [showHandsFree, setShowHandsFree] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lastMessage, setLastMessage] = useState<number>(0)
  const suggestionRef = useRef<HTMLDivElement>(null);
  const [blokingInput, setBlockingInput] = useState<boolean>(false);
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false);
  const [disableHandsFree, setDisableHandsFree] = useState<boolean>(false);
  const [requestingDeal, setRequestingDeal] = useState<boolean>(false);
  const lastItemRef = useRef<HTMLDivElement>(null);
  const { steps, step, stepName } = useTutorial();
  const { messages, isLoadingMessages } = useMessages();
  const queryClient = useQueryClient();
  const cachedMessages = queryClient.getQueryData<Conversation>([
    "chats",
    conversation?.id,
  ]);

  useEffect(() => {
    const dealId = searchParams.get("requestJoinDeal");

    if (dealId) {
      sendRequestJoinDeal(dealId);
    }
  }, [route, searchParams, conversation]);

  useEffect(() => {
    if (steps[stepName] && steps[stepName][step]) {
      setDisableHandsFree(true);
      setShowHandsFree(true);
    }
  }, []);

  useEffect(() => {
    if (allMessages.find(f => f.data.role === "assistant" && f.data.content?.includes("showSecondOnboarding"))) {
      setSecondCardOnboardingLoad(true)
    }
  }, [allMessages])

  const profileAccepted = useMemo((): boolean => {
    if (!currentUser) return false;
    return (
      currentUser?.onboardingStatus == `profileAccepted` ||
      currentUser?.onboardingStatus == `complete`
    );
  }, [currentUser]);

  const onboardingComplete = useMemo((): boolean => {
    if (!currentUser) return false;
    return currentUser.onboardingStatus === "complete";
  }, [currentUser]);

  const messageFirstOnBoarding = useMemo((): IMessage | undefined => {
    return allMessages.find(f => f.data.role === "assistant" && f.data.content?.includes("showFirstOnboarding"))
  }, [allMessages])

  const messageSecondOnBoarding = useMemo((): IMessage | undefined => {
    return allMessages.find(f => f.data.role === "assistant" && f.data.content?.includes("showSecondOnboarding"))
  }, [allMessages])

  const lastMessageIsSecondOnBoarding = useMemo((): boolean => {
    if (!allMessages.length) return false
    const lastMessage = allMessages[allMessages.length - 1]
    if (!lastMessage) return false
    return lastMessage.data.role == "assistant" && lastMessage.data.content?.includes("showSecondOnboarding") ? true : false
  }, [allMessages])

  const showCompleteOnboardingBanner = useMemo((): boolean => {
    return currentUser.onboardingStatus == "profileAccepted"
  }, [allMessages])

  useEffect(() => {
    if (!isLoadingMessages && messages && currentUser?.id) {
      const chats = messages as chatsProps;
      if (Object.keys(chats).length > 0) {
        foundChat(chats);
      }
    }
  }, [messages, isLoadingMessages, currentUser?.id]);

  const foundChat = async (messages: chatsProps) => {
    if (typeof messages !== "object") return
    let foundChat = Object.values(messages).filter(
      (f) =>
        f.context === "assistant" &&
        f.participants?.includes(currentUser.id) &&
        f.participants.includes("RAI")
    );
    if (!foundChat.length && currentUser.onboardingStatus === "pending") {
      const token = (await auth.currentUser?.getIdToken(true)) ?? "";
      await sendMessageToAPI({
        token: token,
        route: "chat/create",
        data: {
          id: currentUser.id,
          participants: ["RAI", currentUser.id],
          context: "assistant",
        },
      });
      return;
    }
    let conversationDoc = foundChat[0];

    console.log("Conversation: ", conversationDoc.id);
    console.log("User: ", currentUser.id);
    setConversation(conversationDoc);
  };

  useEffect(() => {
    if (!conversation?.id) return
    if (cachedMessages?.messages && JSON.stringify(cachedMessages?.messages.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds)) !== JSON.stringify(allMessages)) {
      console.log(`Messages from ${conversation.id}:`, cachedMessages?.messages)
      setAllMessages(
        cachedMessages?.messages.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds)
      );
    }
    setLoading(false);
  }, [cachedMessages, conversation, userLoggedIn]);

  const getLastElementFromChat = () => {
    if (!scrollRef.current) return
    if (scrollRef.current.children.length <= 2) return
    return scrollRef.current.children[scrollRef.current.children.length - 2]

  }

  useEffect(() => {
    // first loading chat AI
    if (loading || !lastItemRef.current || loadMessages) return
    console.log("scroll ref: ", lastItemRef.current)
    if (
      scrollRef.current &&
      conversation?.id &&
      scroll.positions[conversation.id]
    ) {
      console.log("scroll to position ", scroll.positions[conversation.id])
      scrollRef?.current?.scrollTo({
        top: scroll.positions[conversation.id],
        behavior: "instant",
      });

    } else {
      console.log("scroll SCROLLOU PARA O FUNDO")
      waitingAiEl?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "end",
      });
    }
    setTimeout(() => {
      setLoadMessages(true);
    }, 100);
    setLastMessagePosition()
  }, [loading, lastItemRef.current]);

  // const scrollToSecondCardOnboarding = () => {
  //   const elementRef = getLastElementFromChat();

  //   console.log("TENTOU SCROLLAR ", elementRef)
  //   if (elementRef && scrollRef.current) {
  //     const headerOffset = 196

  //     const element = elementRef as HTMLElement;
  //     const elementPosition = element.offsetTop;

  //     const offsetPosition = elementPosition - headerOffset;
  //     console.log("SCROLL TO ", offsetPosition)
  //     scrollRef.current.scrollTo({
  //       top: offsetPosition,
  //       behavior: "smooth",
  //     });
  //     setSecondCardOnboardingLoad(true)
  //   }
  // };

  const setLastMessagePosition = () => {
    if (!scrollRef.current) return
    setLastMessage(scrollRef.current.children.length - 2)
  }

  const scrollToNextMessage = (headerOffset: number = 0) => {
    if (!scrollRef.current) return;

    const lastCurrentMessageIndex = scrollRef.current.children.length - 2;
    if (lastCurrentMessageIndex === lastMessage) {
      if (conversation?.awaitingAIMessage) {
        waitingAiEl?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "end",
        });
      }
      return
    }

    const element = scrollRef.current.children[lastCurrentMessageIndex] as HTMLElement;
    if (element) {
      const elementTopPosition = element.offsetTop - headerOffset;
      // const elementHeight = element.scrollHeight;
      // const containerHeight = scrollRef.current.clientHeight;

      // const scrollPosition = elementTopPosition - (containerHeight / 2) + (elementHeight / 2);
      console.log("scroll headerOffset ", headerOffset)
      console.log("scroll Element to scroll ", element)
      console.log("scroll Element position ", elementTopPosition)
      scrollRef.current.scrollTo({
        top: elementTopPosition,
        behavior: "smooth",
      });

      setLastMessage(lastCurrentMessageIndex);
    }
  };

  useEffect(() => {
    console.log("scroll !lastMessageIsSecondOnBoarding ", !lastMessageIsSecondOnBoarding)
    if (loadMessages && (messageSecondOnBoarding || onboardingComplete) && !lastMessageIsSecondOnBoarding) {
      console.log("scroll scrollToNextMessage")
      scrollToNextMessage()
    }
  }, [allMessages, scrollRef.current, lastMessageIsSecondOnBoarding]);

  useEffect(() => {
    if (conversation?.awaitingAIMessage) {
      scrollToNextMessage()
    }
  }, [conversation?.awaitingAIMessage]);

  const sendMessage = useCallback(
    async (text: string) => {
      try {
        if (!text || sendingMessage) return;
        if (!conversation) return;
        setSendingMessage(true)
        setConversation((prev) => {
          if (prev) return { ...prev, awaitingAIMessage: true };
          return null;
        });

        cleanSuggestions();
        const newMessage: IMessage = {
          conversation: conversation!.id,
          timestamp: Timestamp.now(),
          from: currentUser.id, // ID of the user sending the message
          data: {
            content: text,
            role: "user",
          },
        };

        // setAllMessages((prev) => [...prev, newMessage].sort((a, b) => a.timestamp.seconds - b.timestamp.seconds));

        queryClient.setQueryData(["chats", conversation.id], (prev: Conversation) => ({
          ...prev,
          messages: [...allMessages, newMessage],
        }));

        const token = (await auth.currentUser?.getIdToken(true)) ?? "";
        await sendMessageToAPI({
          token: token,
          route: "chat/create",
          data: {
            id: currentUser.id,
            conversationId: conversation!.id,
            awaitingAIMessage: true,
            messageCount: allMessages.length + 1,
            lastInteraction: Timestamp.now(),
          },
        });

        await sendMessageToAPI({
          token: token,
          route: "chat/send-message",
          data: { ...newMessage, id: currentUser.id },
        });

        const id = auth.currentUser?.uid ?? "";
        await sendMessageToAI({
          token: token,
          id: id,
          conversationId: conversation.id,
        });
        setSendingMessage(false)
      } catch (error: any) {
        setSendingMessage(false)
        addNotification({ title: "Error", body: error, type: "error" });
      } finally {
        setSendingMessage(false)
      }
    },
    [currentUser, conversation, messages, sendingMessage]
  );

  const sendRequestJoinDeal = async (dealId: string) => {
    try {
      if (!conversation?.id || requestingDeal) return;
      setRequestingDeal(true)
      const token = (await auth.currentUser?.getIdToken(true)) ?? "";

      const params = new URLSearchParams(searchParams.toString());
      params.delete("requestJoinDeal");
      const newQuery = params.toString();
      route.replace(`${window.location.pathname}?${newQuery}`, {
        scroll: false,
      });

      await sendMessageToAPI({
        token: token,
        route: "deal/join",
        data: {
          id: currentUser.id,
          conversationId: conversation.id,
          dealId: dealId,
        },
      });
      setRequestingDeal(false)
      // await sendMessageToAI({
      //   token: token,
      //   id: currentUser.id,
      //   conversationId: conversation.id,
      // });
    } catch (error: any) {
      addNotification({ title: "Error", body: error, type: "error" });
    }
  };

  const isWaitingAI = useMemo(() => {
    if (conversation) {
      return conversation.awaitingAIMessage;
    } else {
      return false;
    }
  }, [conversation]);

  const refuseProfile = async () => {
    try {
      const messageError =
        "We are sorry for the inconvenience, it appears your profile was incorrectly registered. You will receive an email from YPO Admin to help you get into the application";
      const newMessage: IMessage = {
        conversation: conversation!.id,
        timestamp: Timestamp.now(),
        from: "RAI",
        data: {
          content: messageError,
          role: "assistant",
        },
      };
      setAllMessages((prev) => [...prev, newMessage].sort((a, b) => a.timestamp.seconds - b.timestamp.seconds));
      const token = (await auth.currentUser?.getIdToken(true)) ?? "";
      await sendMessageToAPI({
        token: token,
        route: "chat/refuse-profile",
        data: {
          id: currentUser.id,
          conversation: conversation?.id!,
          message: messageError,
        },
      });
    } catch (error: any) {
      addNotification({ title: "Error", body: error, type: "error" });
    }
  };

  const acceptProfile = useCallback(
    async (accepted: boolean, message: IMessage) => {
      try {
        if (currentUser.onboardingStatus === "profileAccepted") return;
        setConversation((prev) => {
          if (!prev) return prev
          return { ...prev, awaitingAIMessage: true }
        })
        setUser({
          ...currentUser,
          onboardingStatus: accepted ? "profileAccepted" : "error",
        });

        let findMessage = allMessages.filter((f) => f.id == message.id);
        if (findMessage.length)
          findMessage[0].data.content = JSON.stringify({
            type: "showFirstOnboarding",
            accepted: accepted,
          });
        const token = (await auth.currentUser?.getIdToken(true)) ?? "";
        await sendMessageToAPI({
          token: token,
          route: "chat/accept-profile",
          data: {
            id: currentUser.id,
            conversation: conversation?.id!,
            messageId: message.id,
            onboardingStatus: accepted ? "profileAccepted" : "error",
            accepted: accepted,
          },
        });
        if (accepted) {
          await addOnboarding("second", conversation?.id!);
          scrollToNextMessage(196)
        } else {
          refuseProfile();
        }
      } catch (error: any) {
        addNotification({ title: "Error", body: error, type: "error" });
      }
    },
    [currentUser, conversation]
  );

  const completeOnboarding = useCallback(
    async () => {
      try {
        if (currentUser.onboardingStatus === "complete") return;
        if (!messageSecondOnBoarding) return
        setBlockingInput(true)
        const token = (await auth.currentUser?.getIdToken(true)) ?? "";
        await sendMessageToAPI({
          token: token,
          route: "chat/complete-onboarding",
          data: {
            id: currentUser.id,
            conversation: conversation?.id!,
            messageId: messageSecondOnBoarding.id,
            onboardingStatus: "complete",
            accepted: true,
          },
        });
        await sendMessageToAI({
          token: token,
          id: currentUser.id,
          conversationId: conversation?.id!,
        });
        setBlockingInput(false)
      } catch (error: any) {
        setBlockingInput(false)
        addNotification({ title: "Error", body: error, type: "error" });
      }
    },
    [currentUser, conversation, messageSecondOnBoarding]
  );

  const addOnboarding = async (which: string, conversationId?: string) => {
    try {
      if (!conversationId) return;
      const type =
        which === "first"
          ? "showFirstOnboarding"
          : which === "second"
            ? "showSecondOnboarding"
            : "showThirdOnboarding";
      if (
        messages[conversationId].messages?.filter(
          (f) =>
            typeof f.data.content === "string" && f.data.content?.includes(type)
        ).length
      )
        return;
      const newMessage: IMessage = {
        conversation: conversationId,
        timestamp: Timestamp.now(),
        from: "RAI",
        data: {
          content: "",
          role: "assistant",
        },
      };
      const token = (await auth.currentUser?.getIdToken(true)) ?? "";
      await sendMessageToAPI({
        token: token,
        route: "chat/create",
        data: {
          id: currentUser.id,
          conversationId: conversationId,
          awaitingAIMessage: true,
          messageCount: allMessages.length + 1,
          lastInteraction: Timestamp.now(),
        },
      });

      if (which === "first")
        newMessage.data.content = JSON.stringify({
          type: "showFirstOnboarding",
          accepted: false,
        });
      if (which === "second")
        newMessage.data.content = JSON.stringify({
          type: "showSecondOnboarding",
          accepted: false,
        });
      if (which === "third") {
        const UUID = uuidv4();
        newMessage.data = {
          tool_call_id: UUID,
          content: JSON.stringify({ type: "showThirdOnboarding" }),
          role: "tool",
        };
        await new Promise((resolve) =>
          setTimeout(async () => {
            const token = (await auth.currentUser?.getIdToken(true)) ?? "";
            await sendMessageToAPI({
              token: token,
              route: "chat/send-message",
              data: {
                id: currentUser.id,
                conversation: conversationId,
                timestamp: Timestamp.now(),
                from: "RAI",
                data: {
                  tool_calls: [
                    {
                      function: {
                        name: "showThirdOnboarding",
                        arguments: JSON.stringify({ userId: currentUser.id }),
                      },
                      id: UUID,
                      type: "function",
                    },
                  ],
                  content: null,
                  role: "assistant",
                },
              },
            });
            resolve(true);
          }, 500)
        );
      }

      await new Promise((resolve) =>
        setTimeout(async () => {
          newMessage.timestamp = Timestamp.now();
          await sendMessageToAPI({
            token: token,
            route: "chat/send-message",
            data: { ...newMessage, id: currentUser.id },
          });
          setTimeout(async () => {
            await sendMessageToAPI({
              token: token,
              route: "chat/create",
              data: {
                id: currentUser.id,
                conversationId: conversationId,
                awaitingAIMessage: false,
                messageCount: allMessages.length + 1,
                lastInteraction: Timestamp.now(),
              },
            });
            waitingAiEl?.current?.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
            resolve(true);
          }, 300);
        }, 1500)
      );
    } catch (error: any) {
      addNotification({ title: "Error", body: error, type: "error" });
    }
  };

  useEffect(() => {
    // deleteDoc(doc(db, "conversations", "JpogZGqtOX6MeWIa8LWe"))
    // updateUser({ ...currentUser, onboardingStatus: "pending" })
    if (
      currentUser.onboardingStatus === "pending" &&
      loadMessages &&
      !messages.length &&
      !isLoadingMessages &&
      conversation &&
      !messageFirstOnBoarding
    ) {
      addOnboarding("first", conversation.id);
    }
  }, [loadMessages, messageFirstOnBoarding]);

  useEffect(() => {
    if (!userLoggedIn) route.push("/authentication");
  }, [userLoggedIn]);

  useEffect(() => {
    if (isWaitingAI) {
      // AI response scroll down
      setTimeout(() => {
        waitingAiEl?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "end",
        });
      }, 200);
      // If AI doesn't respond within 5 minutes remove the loading response
      setTimeout(async () => {
        if (isWaitingAI && conversation && conversation.awaitingAIMessage) {
          try {
            const token = (await auth.currentUser?.getIdToken(true)) ?? "";
            if (!token) return;
            await sendMessageToAPI({
              token: token,
              route: "chat/create",
              data: {
                id: currentUser.id,
                conversationId: conversation.id,
                awaitingAIMessage: false,
                messageCount: allMessages.length + 1,
                lastInteraction: Timestamp.now(),
              },
            });
          } catch (error: any) {
            addNotification({ title: "Error", body: error, type: "error" });
          }
        }
      }, 300000);
    }
  }, [isWaitingAI]);

  const handleScroll = () => {
    if (scrollRef.current && conversation?.id) {
      const { scrollTop, scrollLeft } = scrollRef.current;
      scroll.set(conversation.id, Math.round(scrollTop));
    }
  };

  const handleSuggestion = (show: boolean) => {
    if (show && !isWaitingAI) {
      const newMessage: IMessage = {
        conversation: conversation!.id,
        timestamp: Timestamp.now(),
        from: currentUser.id,
        data: {
          content: [
            "Show me profiles.",
            "What sessions should I attend?",
            "Show me deals.",
          ],
          role: "suggestions",
          reference: suggestionRef,
          referenceScroll: waitingAiEl,
        },
      };
      setAllMessages((prev) => [...prev, newMessage].sort((a, b) => a.timestamp.seconds - b.timestamp.seconds));
    } else {
      cleanSuggestions();
    }
    setShowSuggestion(show);
  };

  const cleanSuggestions = () => {
    setAllMessages((prev) => [
      ...prev.filter((f) => f.data.role !== "suggestions").sort((a, b) => a.timestamp.seconds - b.timestamp.seconds),
    ]);
    setShowSuggestion(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      suggestionRef.current &&
      !suggestionRef.current.contains(event.target as Node)
    ) {
      cleanSuggestions();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onboardedViaAI = useMemo(() => {
    return allMessages.length > 2;
  }, [allMessages]);

  const isResponsing = useMemo((): { messages: string[]; action: string } => {
    try {
      const lastMessage = allMessages.slice(-1).pop();
      if (!lastMessage) return { messages: [], action: "" };
      if (lastMessage.from != "RAI") return { messages: [], action: "" };
      if (lastMessage.data.role !== "assistant" || lastMessage.data.content)
        return { messages: [], action: "" };
      if (!lastMessage.data.tool_calls?.length)
        return { messages: [], action: "" };
      const actionName = lastMessage.data.tool_calls[0].function.name || "";
      if (!actionName) return { messages: [], action: "" };
      const { message } = actionAI[actionName];
      if (message) return { messages: message, action: actionName };
      return { messages: [], action: "" };
    } catch (error) {
      console.log(error);
      return { messages: [], action: "" };
    }
  }, [allMessages]);

  return (
    <div className="flex flex-col h-[calc(100dvh_-_55px)] justify-between relative">
      {initialized ?
        <>
          <div
            key={"aiChat"}
            ref={scrollRef}
            className={
              `snap-y overflow-y-visible overflow-x-hidden space-y-6 flex-1 flex flex-col px-[30px] py-[20px] notranslate relative scrollbar-thin ${!onboardingComplete ? "pt-[200px]" : ""}`}
            onScroll={handleScroll}
          >
            {
              !loadMessages && currentUser.onboardingStatus !== "pending" ?
                <div className="absolute top-0 left-0 flex-1 flex flex-col space-y-6 w-full h-full z-10 bg-white px-[30px] py-[20px]">
                  <div className="flex justify-start">
                    <Skeleton className="h-[80px]" />
                  </div>
                  <div className="flex justify-start">
                    <Skeleton className="h-[131px]" />
                  </div>
                  <div className="flex justify-end">
                    <Skeleton className="h-[45px] !w-[150px]" />
                  </div>
                  <div className="flex justify-start">
                    <Skeleton className="h-[45px] !w-[150px]" />
                  </div>
                  <div className="flex justify-end">
                    <Skeleton className="h-[45px] !w-[150px]" />
                  </div>
                  <div className="flex justify-start">
                    <Skeleton className="h-[45px] !w-[150px]" />
                  </div>
                </div>
                : null
            }
            {
              allMessages.length ?
                allMessages.map((message, key) => {
                  const isLastItem = key === allMessages.length - 1;
                  return (
                    <div key={key + message.id!}>
                      <DynamicComponent
                        key={key + message.id!}
                        message={message}
                        messagesLength={allMessages.length}
                        index={key}
                        loading={loading}
                        onboardedViaAI={onboardedViaAI}
                        acceptProfile={(decision, message) =>
                          acceptProfile(decision, message)
                        }
                        completeOnboarding={(message) => completeOnboarding()}
                        onSendMessage={(text) => sendMessage(text)}
                      />
                      {
                        isLastItem ?
                          <div ref={lastItemRef} className="!m-0 !p-0"></div>
                          : null
                      }
                    </div>
                  )
                })
                : <div ref={lastItemRef} className="!m-0 !p-0"></div>
            }
            <div ref={waitingAiEl}>
              {isWaitingAI && (
                <div className="space-y-3 flex flex-col">
                  {isResponsing.action ? (
                    <PreLoadingComponents {...isResponsing} />
                  ) : (
                    <MessageAssistant message={<LoadingAiResponse />} />
                  )}
                </div>
              )}
            </div>
          </div>
          {profileAccepted && (
            <div className="w-full h-auto px-[30px] pt-4 pb-8 bg-white">
              <div className="w-full flex flex-col justify-end space-y-[10px]">
                <div className="flex flex-row items-center justify-between">
                  <Alert text={"Ask me anything"} />
                  {onboardingComplete && (
                    <Suggestions
                      clicked={showSuggestion}
                      disableButton={isWaitingAI}
                      onClick={(show) => handleSuggestion(show)}
                    />
                  )}
                </div>
                <MessageInput
                  placeholder={
                    isWaitingAI
                      ? "Awaiting RAI response"
                      : "Type your instructions here"
                  }
                  // setShowHandsFree={onboardingComplete}
                  // onHandsFree={() => setShowHandsFree(true)}
                  onSendMessage={(text) => sendMessage(text)}
                  disableButtons={isWaitingAI || blokingInput ? true : false}
                />
              </div>
            </div>
          )}
          {
            showCompleteOnboardingBanner &&
            <BannerOnboarding onClick={completeOnboarding} />
          }
          {showHandsFree &&
            <HandsFree
              disableRecording={disableHandsFree}
              conversationId={(conversation && conversation.id) || ""}
              show={showHandsFree}
              messages={allMessages}
              isWaitingAI={isWaitingAI}
              onClose={() => setShowHandsFree(false)}
            />}

        </>

        :
        <motion.div
          className="flex-1 flex flex-col justify-center items-center"
          initial={{ x: 0, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
          }}
        >
          <Loading className="!h-20 !w-20 -mt-20"></Loading>
        </motion.div>
      }
    </div>
  );
}
