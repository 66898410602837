import IconIA from "../IconIA";

interface SuggestionsProps {
    suggestions?: string[];
    clicked: boolean;
    disableButton?: boolean;
    onClick: (show: boolean) => void;
}

export default function Suggestions({ clicked = false, disableButton = false, onClick }: SuggestionsProps) {

    const handleClickSuggestion = () => {
        if(disableButton) return
        onClick(!clicked)
    }

    return (
        <div className={`flex flex-row space-x-2 items-center py-[6px] px-3 rounded-full border-2 transition-all duration-500 ${clicked ? "border-primary-4" : "border-[#E0EAFB]"} ${disableButton ? "bg-grey-2 !border-[#E0EAFB]" : "bg-grey-1.1"}`} onClick={handleClickSuggestion}>
            <IconIA className="text-primary-3.2" />
            <p className="font-bold text-13px text-[#4F6992]">suggestions</p>
        </div>
    )
}
